import * as React from "react";
import { useLocalObservable } from "mobx-react-lite";

import { IStoresTypes } from "./types";

export const storeContext = React.createContext<IStoresTypes | null>(null);
interface IProvider {
	children: React.ReactNode;
	store: IStoresTypes;
}

const StoreProvider = ({ children, store }: IProvider): React.ReactNode => {
	const storeHook = useLocalObservable(() => store);
	return <storeContext.Provider value={storeHook}>{children}</storeContext.Provider>;
};

export default StoreProvider;
