import { Flex, Typography } from "antd";
import { EllipsisConfig } from "antd/es/typography/Base";

import s from "./styles.module.scss";

interface InfoBlockProps extends EllipsisConfig {
	title?: string;
	message?: string;
}

export const InfoBlock = ({ title, message, ...ellipsisProps }: InfoBlockProps) => {
	if (!title && !message) return null;

	return (
		<Flex vertical gap={4}>
			{title && (
				<Typography.Text ellipsis={!ellipsisProps.expanded} className={s.infoBlockTitle}>
					{title}:
				</Typography.Text>
			)}

			<Typography.Paragraph ellipsis={ellipsisProps} className={s.infoBlockMessage}>
				{message}
			</Typography.Paragraph>
		</Flex>
	);
};
