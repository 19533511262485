import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { IMenuItem, IPathParams } from "@/app/routing/types";
import { useLateInitContext } from "@/shared/hooks/use-late-init-context";

import { AsideContext } from "./context";
import s from "./aside.module.scss";

export interface MenuItemProps {
	menuItem?: IMenuItem;
	route: string;
	pathParams?: IPathParams;
}

export const MenuItem = ({ route, menuItem, pathParams }: MenuItemProps) => {
	const { afterClick } = useLateInitContext(AsideContext);

	if (!menuItem) return null;

	const { icon, title, to } = menuItem;

	const path = to ? to(pathParams || {}) : `/${route}`;

	return (
		<div data-testid="item" className={s.item}>
			<NavLink
				data-testid="link"
				className={({ isActive }) => classNames(s.link, { [s.activeLink]: isActive })}
				to={path}
				onClick={afterClick}
			>
				{icon}
				<span data-testid="link" className={s.text}>
					{title}
				</span>
			</NavLink>
		</div>
	);
};
