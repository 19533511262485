import { useParams } from "react-router-dom";

import { PARTY_ROUTES_SCHEME } from "@/app/routing/route-scheme";

import { useGetNavigationList } from "./shared/hooks/use-get-navigation-list";

interface PartyQueryParams {
	section: string;
	party: string;
}

export const PartyNavigationList = () => {
	const menuItems = PARTY_ROUTES_SCHEME.filter(({ menuItem }) => !!menuItem);

	const { party } = useParams<keyof PartyQueryParams>() as PartyQueryParams;

	return useGetNavigationList(menuItems, { party });
};
