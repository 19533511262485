import { useContext } from "react";

import { storeContext } from "./provider";
import { IStoresTypes } from "./types";

export const useStore = (): IStoresTypes => {
	const store = useContext(storeContext);
	if (!store) {
		throw new Error("No mobx store instance found! You probably forgot to add context provider.");
	}
	return store;
};
