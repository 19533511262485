import { Instance, t } from "mobx-state-tree";

import { LocalStorageKeys } from "@/shared/constants/local-storage-keys";
import { Abilities, AbilityType } from "@/shared/types/abilities-types";
import { getFromLocalStorage } from "@/shared/utils/local-storage/get-from-local-storage";

import { checkAbility, checkParty } from "../utils";

export const AbilitiesStore = t
	.model("AbilitiesStore", {
		party: t.maybeNull(t.string),
	})
	.views(() => ({
		get abilities(): Abilities {
			const jsonAbilities = getFromLocalStorage(LocalStorageKeys.Abilities);

			if (jsonAbilities) {
				const data = JSON.parse(jsonAbilities) as Abilities;

				return data;
			}

			return {};
		},
	}))
	.actions((self) => ({
		setParty(party: string | null) {
			self.party = party;
		},

		resetParty() {
			self.party = null;
		},

		checkMainLayerAbility(ability: AbilityType) {
			return checkAbility(self.abilities, ability);
		},

		checkPartyLayerAbility(party: string, ability: AbilityType) {
			return checkParty(self.abilities, ability, party);
		},

		hasAbility(ability: AbilityType) {
			const isPartyLayer = !!self.party;

			if (!isPartyLayer) return checkAbility(self.abilities, ability);

			const { party } = self;

			if (!party) {
				return false;
			}

			return checkParty(self.abilities, ability, party);
		},
	}));

export type IAbilitiesStore = Instance<typeof AbilitiesStore>;
