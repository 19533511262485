import { IStore } from "@/app/model";
import { useStore } from "@/app/model/use-store";

import { AbilityType } from "../../../shared/types/abilities-types";

export const useHasAbilities = (abilityName: AbilityType) => {
	const { abilitiesState } = useStore() as IStore;
	const { hasAbility } = abilitiesState;

	return hasAbility(abilityName);
};
