import {
	ToastContent,
	ToastOptions,
	ToastPromiseParams,
	toast as toastDefault,
} from "react-toastify";

import { TOASTS_MOBILE_DEVICE_WIDTH } from "@/shared/constants/media-query";
import { checkDevice } from "@/shared/utils/check-device";

import { CONTAINER_ID, DEFAULT_TOAST_SETTINGS, SUCCESS_TOAST_SETTINGS } from "./consts";

const getSuccessOptions = () => {
	const isMobile = checkDevice(TOASTS_MOBILE_DEVICE_WIDTH);

	if (isMobile) return { ...SUCCESS_TOAST_SETTINGS, containerId: CONTAINER_ID.DEFAULT };

	return SUCCESS_TOAST_SETTINGS;
};

type Toast = typeof toastDefault;
type ToastKey = keyof Toast;
type BaseToastMethod = "warning" | "info" | "loading" | "warn" | "dark";

const baseToastMethods: ToastKey[] = ["warning", "info", "loading", "warn", "dark"];

const isBaseToastMethod = (method: ToastKey): method is BaseToastMethod =>
	baseToastMethods.includes(method);

const toastInit = () => {
	const toast = new Proxy<Toast>(toastDefault, {
		apply(target, _thisArg, [content, options]) {
			return target(content, { ...DEFAULT_TOAST_SETTINGS, ...options });
		},

		get(target, property: ToastKey, receiver) {
			if (property === "success") {
				return (content: ToastContent, options?: ToastOptions) =>
					target.success(content, { ...getSuccessOptions(), ...options });
			}

			if (property === "error") {
				return (content: ToastContent, options?: ToastOptions) =>
					target.error(content, { ...DEFAULT_TOAST_SETTINGS, ...options });
			}

			if (property === "promise") {
				return (
					promise: Promise<unknown> | (() => Promise<unknown>),
					promiseParams: ToastPromiseParams,
					options?: ToastOptions
				) => target.promise(promise, promiseParams, { ...DEFAULT_TOAST_SETTINGS, ...options });
			}

			if (isBaseToastMethod(property)) {
				return (content: ToastContent, options?: ToastOptions) =>
					target[property](content, { ...DEFAULT_TOAST_SETTINGS, ...options });
			}

			return Reflect.get(target, property, receiver);
		},
	});

	return toast;
};

export const uiToast = toastInit();
