import { Link } from "react-router-dom";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";

interface BreadCrumbItemProps {
	route: ItemType;
	paths: string[];
}

const checkIsLastPath = (paths: string[], currentPath?: string) => {
	if (!paths.length || !currentPath) return true;

	return currentPath === paths[paths.length - 1];
};

export const BreadCrumbItem = ({ route, paths }: BreadCrumbItemProps) => {
	const isLast = checkIsLastPath(paths, route.path);

	return isLast ? <span>{route.title}</span> : <Link to={route.path || ""}>{route.title}</Link>;
};
