import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { IStore } from "@/app/model";
import { useStore } from "@/app/model/use-store";
import { MainRoutes } from "@/app/routing/types";
import LogoIconSvg from "@/shared/assets/images/logo.svg?react";

import s from "./logo.module.scss";

export const Logo = observer(() => {
	const { getIsLightTheme } = useStore() as IStore;

	return (
		<NavLink to={MainRoutes.Root} className={s.logoButton}>
			<LogoIconSvg className={s.logo} fill={getIsLightTheme ? "black" : "white"} />
		</NavLink>
	);
});
