import React from "react";
import { Divider } from "antd";
import { EllipsisConfig } from "antd/es/typography/Base";

import { CollapsiblePlace, InfoBlockType } from "../types";

import { InfoBlock } from "./info-block";
import s from "./styles.module.scss";

interface InfoBlocksProps {
	infoBlocks: InfoBlockType[];
	getEllipsisProps: (place: CollapsiblePlace, isLastInfoBlock: boolean) => EllipsisConfig;
}

export const InfoBlocks = ({ infoBlocks, getEllipsisProps }: InfoBlocksProps) => (
	<>
		{infoBlocks.map((infoBlock, index, arr) => {
			const isLastInfoBlock = index === arr.length - 1;

			return (
				// eslint-disable-next-line react/no-array-index-key
				<React.Fragment key={index}>
					<InfoBlock {...infoBlock} {...getEllipsisProps("infoBlock", isLastInfoBlock)} />
					{!isLastInfoBlock && <Divider className={s.divider} />}
				</React.Fragment>
			);
		})}
	</>
);
