import { ReactNode } from "react";

import { AbilityType } from "@/shared/types/abilities-types";

export interface IPathParams {
	[key: string]: string;
}
export interface IMenuItem {
	title?: string;
	icon?: ReactNode;
	to?: (pathParams: IPathParams) => string;
}

export interface BreadcrumbMenuItem {
	key?: React.Key;
	title?: React.ReactNode;
	label?: React.ReactNode;
	path?: string;
	href?: string;
	ability?: AbilityType;
}

export interface CrumbHandle {
	crumb: {
		path?: string;
		title?: React.ReactNode;
		menu?: { items?: BreadcrumbMenuItem[] };
	};
}
export interface IRouteConfig {
	path: string;
	// type: string;
	element?: JSX.Element | null;
	ability?: AbilityType;
	children?: IRouteConfig[];
	menuItem?: IMenuItem;
	handle?: CrumbHandle;
}

export interface IRouteItem {
	path: string;
	isAuth: boolean;
	element: ReactNode;
	children?: IRouteItem[];
}

export enum RouteEnum {
	INDEX = "",
	PARTY = "party",
	BOT = "bot",
	// STORIES = "stories",
}

export enum MainRoutes {
	Root = "",
	Any = "/*",
	Error = "error-page",
	AllBots = "all-bots",
	MarketCheckPage = "market-check",
	AccessPage = "access",
	TeamsPage = "teams",
	CEXCreatingPage = "cex-bot-creating",
	DEXCreatingPage = "dex-bot-creating",
	ExchangeAccountingPage = "exchange-accounting",
	Login = "login",
	Stories = "stories",
}

export enum StoryRoutes {
	PNLV2Story = "pnlv2",
	ToastsStory = "toasts",
	GraphStory = "graph",
	DatePickerStory = "date-picker",
	TableStory = "table",
	Form = "form",
	WidgetWrapper = "widget-wrapper",
	Modal = "modal",
}

export enum PartyRoutes {
	Bots = "bots",
	Dashboard = "dashboard",
	DashboardV2 = "dashboard-v2",
	Strategies = "strategies",
	Modules = "modules",
	Multigrid = "multigrid",
	Funding = "funding",
	CandlesAnalytic = "candles-analytic",
	MarketsDetailed = "market-detailed",
}

export enum CEXRoutes {
	Stats = "stats",
	APIKeys = "api-keys",
	Settings = "settings",
	Exchange = "exchange",
	Grid = "grid",
	/** crutch for displaying the multigrid tab on bot layer */
	Multigrid = "multigrid",
}

export enum DEXRoutes {
	Stats = "stats",
	Settings = "settings",
	Funding = "funding",
}

export enum DEXV2Routes {
	Stats = "stats",
	Settings = "settings",
	Swap = "swap",
}

export type LayerType = "main" | "party" | "bot";

export const BOT_TYPES = ["CEX", "DEX", "DEX_V2"] as const;

export type BotType = (typeof BOT_TYPES)[number];

export type CEXTabs = `${CEXRoutes}`;

export type DEXTabs = `${DEXRoutes}`;

export type DEXV2Tabs = `${DEXV2Routes}`;

type BotTabsMap = {
	CEX: CEXTabs;
	DEX: DEXTabs;
	DEX_V2: DEXV2Tabs;
};

type BotTabs<T extends BotType> = BotTabsMap[T];
export interface GetBotLinkProps<T extends BotType> {
	party: string;
	botType: T;
	uuid: string;
	botTab?: BotTabs<T>;
}

export type PartyPage = `${PartyRoutes}`;

export interface GetPartyLinkProps {
	party: string;
	partySection: PartyPage;
}

export type BotLayerParams = {
	botType: BotType;
};
