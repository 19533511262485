import {
	CEXBotDTO,
	SummaryBotsInfoDTO,
	GeneratedVolumesDTO,
	DepthDTO,
	BalancesDTO,
	StatsPerPeriodDTO,
	VirtualWallsDTO,
	GridGrinderDTO,
	SettingsDTO,
} from "./types";

const cex_bot: CEXBotDTO = {
	base: "DVDX",
	bot_uuid: "c59c882f-5d04-4187-97ac-37a21f41e592",
	created_at: 1659357578,
	diff: "Swap: cake2, Diff: 99",
	exchange: "bitmart",
	link: "https://www.bitmart.com/trade/en?layout=pro&symbol=DVDX_USDT",
	message:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
	modifyTime: 1670867958,
	modifyVolume: false,
	name: "my_best_favorite_bot",
	party: "DVDX",
	quote: "USDT",
	side: "BUY/SELL",
	status: 0,
	timeDontWork: 1681408116,
	updated_at: 1707239287,
};

const cex_bots: CEXBotDTO[] = Array.from({ length: 10 }, () => ({
	...cex_bot,
	bot_uuid: `${Math.random()}`,
}));

const summary_bots_info: SummaryBotsInfoDTO = {
	total_bots: 185,
	red_bots: 30,
	green_bots: 45,
	yellow_bots: 10,
	turned_off_bots: 100,
	bots_with_errors: {
		meta: { amze_usdt: "12m 45s", amze_usdt2: "12m", amze_usdt3: "12m 45s" },
		market: "usdt_btc_mxc",
		info: "5",
	},
	volume_not_target: {
		meta: { amze_usdt: "10%", amze_usdt2: "1%", amze_usdt3: "10%" },
		market: "usdt_vinu_binance",
		info: "49%",
	},
	low_liquidity: {
		meta: {
			amze_usdt: "OKX, 2% depth, <$5",
			amze_usdt2: "OKX, 2% depth, <$5,000",
			amze_usdt3: "OKX, 2% depth, <$5,000",
		},
		market: "eth_welt_bybit",
		info: "OKX, 2% depth, <$5,000",
	},
};

const generated_volumes: GeneratedVolumesDTO = {
	targ: "300.000",
	our: "26.385",
	org: "6.555",
	total: "32.940",
	per_ours: "80",
	c_fees: "304",
};

const depth: DepthDTO = {
	buy: {
		our_quote: { label: "METFI", value: "19.17" },
		our_base: { label: "USD", value: "36.431" },
		avg_price: "1.897",
	},
	sell: {
		our_quote: { label: "METFI", value: "19.17" },
		our_base: { label: "USD", value: "36.431" },
		avg_price: "1.897",
	},
};

const balances: BalancesDTO = {
	free_balance: [
		{ value: "10.000", label: "USTD", percent: "66" },
		{ value: "20.000", label: "Token", percent: "91" },
		{ value: "15.000", label: "USDC", percent: "50" },
		{ value: "20.000", label: "BTC", percent: "91" },
	],
	locked_balance: [
		{ value: "10.000", label: "USTD", percent: "66" },
		{ value: "20.000", label: "Token", percent: "91" },
		{ value: "15.000", label: "USDC", percent: "50" },
		{ value: "20.000", label: "BTC", percent: "91" },
	],
};

const stats_per_period: StatsPerPeriodDTO = {
	start: [
		{ label: "USD", value: "10.000" },
		{ label: "IAG", value: "10.000" },
	],
	stop: [
		{ label: "USD", value: "10.000" },
		{ label: "IAG", value: "10.000" },
	],
	delta: [
		{ label: "USD", value: "55.400" },
		{ label: "IAG", value: "55.400" },
	],
	funding: [
		{ label: "USD", value: "0", type: "withdraw" },
		{ label: "IAG", value: "10.000", type: "deposit" },
	],
	fee: { percent: "0.39", usd: "-250.43" },
	price: {
		price_net: "0.54",
		price_growth: "0.65",
		actual_price: "0.45",
		potent_net_profit: "2000",
	},
};

const virtual_walls: VirtualWallsDTO = {
	buy_wall: {
		active: true,
		max_amount: "250",
		min_amount: "130",
	},
	sell_wall: {
		active: true,
		max_amount: "250",
		min_amount: "130",
	},
};

const grid_grinder: GridGrinderDTO = {
	grid: {
		quote: "23.51",
		sell_order_num: "25",
		buy_order_num: "45",
		status: "active",
		last_update: 1707239287,
	},
	grinder: {
		quote: "23.51",
		sell_order_num: "25",
		buy_order_num: "45",
		status: "active",
		last_update: 1707239287,
	},
};

const settings: SettingsDTO = {
	spread: { current: "2", avg: "0.5" },
	buy_percent: "84",
	range: { percent: "2", pips: "20" },
	candle_closer: "5",
	spread_decompression: "active",
	verify_order: "stopped",
	min_exchange_vol_per_trade: "active",
	avg_week_volatility: "15",
};

export const MOCK_DATA = {
	cex_bots,
	summary_bots_info,
	generated_volumes,
	depth,
	balances,
	stats_per_period,
	virtual_walls,
	grid_grinder,
	settings,
};
