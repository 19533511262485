import { useGetPartyFromUrl } from "../../hooks";

const useGetPartySectionTitle = () => {
	const party = useGetPartyFromUrl();

	if (!party) return "";

	return `party • ${party}`;
};

export const PartyCrumbTitle = () => {
	const title = useGetPartySectionTitle();
	return <span>{title}</span>;
};
