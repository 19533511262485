import { MAIN_PAGES_ROUTES_SCHEME } from "@/app/routing/route-scheme";

import { useGetNavigationList } from "./shared/hooks/use-get-navigation-list";

export const MainNavigationList = () => {
	const menuItems = MAIN_PAGES_ROUTES_SCHEME.filter(({ menuItem }) => !!menuItem);

	const list = useGetNavigationList(menuItems);

	return list;
};
