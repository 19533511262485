import { lazy } from "react";

/**
 * Simple wrapper around React.lazy that supports named imports.
 * Copy from react-lazily: https://github.com/JLarky/react-lazily
 */
export const lazily = <T extends {}, U extends keyof T>(loader: (x?: string) => Promise<T>) =>
	new Proxy({} as unknown as T, {
		get: (_, componentName: string | symbol) => {
			if (typeof componentName === "string") {
				return lazy(() =>
					loader(componentName).then((x) => ({
						default: x[componentName as U] as any as React.ComponentType<any>,
					}))
				);
			}
		},
	});
