import { observer } from "mobx-react-lite";

import { IStore } from "@/app/model";
import { useStore } from "@/app/model/use-store";

import { BreadCrumbs } from "./breadcrumbs";
import HeaderMenu from "./header-menu";
import { Logo } from "./logo";
import { MobileSideMenu } from "./mobile-side-menu";
import s from "./header.module.scss";

export const Header = observer(() => {
	const { authStore, getTheme, getIsMobile } = useStore() as IStore;
	const { getIsAuth } = authStore;

	return (
		<header data-testid="header" className={s.header}>
			<div data-testid="logo" className={s.logoWrapper}>
				{getIsMobile && <MobileSideMenu />}

				<Logo />
			</div>
			{getIsAuth && (
				<>
					{!getIsMobile && (
						<div data-testid="breadCrumbs" className={s.breadCrumbs}>
							<BreadCrumbs />
						</div>
					)}
					<div data-testid="user" className={s.user}>
						<HeaderMenu />
						<style>
							{`
						   html {  
						     color-scheme: ${getTheme};
						   }  
						 `}
						</style>
					</div>
				</>
			)}
		</header>
	);
});
