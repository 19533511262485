import { CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Flex, Typography } from "antd";
import { EllipsisConfig } from "antd/es/typography/Base";

import { ToastType } from "../../types";

import s from "./styles.module.scss";

const Icon = {
	success: <CheckCircleOutlined className={s.successIcon} />,
	error: <WarningOutlined className={s.errorIcon} />,
};

interface ToastTitleProps extends EllipsisConfig {
	toastType: ToastType;
	title?: string;
}

const defaultTitle = {
	success: "Success",
	error: "Error",
};

export const ToastTitle = ({ toastType, title, ...ellipsisProps }: ToastTitleProps) => {
	const preparedTitle = title ?? defaultTitle[toastType];

	return (
		<Flex gap={8} align="center">
			{Icon[toastType]}

			<Typography.Paragraph className={s.title} ellipsis={ellipsisProps}>
				{preparedTitle}
			</Typography.Paragraph>
		</Flex>
	);
};
