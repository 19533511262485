import { ReactNode, useEffect } from "react";

import { IStore } from "@/app/model";
import { useStore } from "@/app/model/use-store";

export const DeviceProvider = ({ children }: { children: ReactNode }) => {
	const { getIsMobileDeviceWidth, setIsMobile } = useStore() as IStore;
	useEffect(() => {
		const media = window && window.matchMedia(getIsMobileDeviceWidth);
		media.addEventListener("change", () => {
			setIsMobile(media.matches);
		});
		return () =>
			media.removeEventListener("change", () => {
				setIsMobile(media.matches);
			});
	}, []);
	return children;
};
