import { Link } from "react-router-dom";

import { useGetPartyAndBotUUIDFromUrl } from "@/app/hooks";

import { IMenuItem } from "../types";

interface CrumbMenuItemProps extends IMenuItem {}

export const BotCrumbMenuItem = ({ title, to }: CrumbMenuItemProps) => {
	const botParams = useGetPartyAndBotUUIDFromUrl();

	if (!botParams || !to) return null;

	const path = to(botParams);

	return <Link to={path}>{title}</Link>;
};
