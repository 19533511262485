import { Key, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import type { MenuProps } from "antd";
import { Button, Dropdown, Menu } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";

import { IStore } from "@/app/model";
import { useStore } from "@/app/model/use-store";
import { MainRoutes } from "@/app/routing/types";

import { MenuItem } from "../../../../shared/types/types";

import s from "./header-menu.module.scss";

function getItem(
	label: ReactNode,
	key?: Key | null,
	icon?: ReactNode,
	children?: MenuItem[],
	type?: "group"
): MenuItem {
	return {
		key,
		icon,
		children,
		label,
		type,
	} as MenuItem;
}

const createBotItems: MenuItem[] = [
	getItem("Create bot", 1, null, [getItem("CEX", null, null), getItem("DEX", null, null)]),
];

const DropDownMenuLabel = (label: string, action?: () => void, color?: string) => (
	<Button className={classNames(s.button, [color])} type="link" onClick={action}>
		{label}
	</Button>
);

const HeaderMenu = () => {
	const { authStore, setIsLightTheme, getIsLightTheme } = useStore() as IStore;
	const { setLogout, userLogin } = authStore;
	const navigate = useNavigate();

	const items: MenuProps["items"] = [
		{
			key: 1,
			label: <Menu mode="vertical" items={createBotItems} />,
		},
		{
			key: 2,
			label: DropDownMenuLabel("Switch theme", () => setIsLightTheme(!getIsLightTheme)),
		},
		{
			key: 3,
			label: DropDownMenuLabel("Switch main color"),
		},
		{
			key: 4,
			label: DropDownMenuLabel("Update abilities"),
		},
		{
			key: 5,
			label: DropDownMenuLabel("Logout", () => {
				setLogout();
				navigate(MainRoutes.Login);
			}),
		},
		{
			key: 6,
			label: DropDownMenuLabel("Roadmap", undefined, "orange"),
		},
	];

	return (
		<Dropdown menu={{ items }} placement="bottomRight">
			<Button className={s.trigger} type="link">
				{userLogin}
			</Button>
		</Dropdown>
	);
};

export default observer(HeaderMenu);
