import { ToastOptions } from "react-toastify";

export const CONTAINER_ID = {
	DEFAULT: "defaultToastsContainer",
	SUCCESS: "successToastsContainer",
} as const;

export const DEFAULT_TOAST_SETTINGS: ToastOptions = {
	autoClose: 4000,
	containerId: CONTAINER_ID.DEFAULT,
	closeButton: false,
	icon: false,
};

export const SUCCESS_TOAST_SETTINGS: ToastOptions = {
	...DEFAULT_TOAST_SETTINGS,
	autoClose: 1000,
	containerId: CONTAINER_ID.SUCCESS,
};
