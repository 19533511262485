import { useEffect } from "react";
import { BarChartOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { Tooltip } from "antd";
import { observer } from "mobx-react-lite";

import { IStore } from "@/app/model";
import { useStore } from "@/app/model/use-store";

import { wsConnection } from "./ws-connection";
import WSStore from "./ws-store";
import s from "./status-connection.module.scss";

const store = WSStore.create({}, {});
const StatusConnection = () => {
	const {
		getFirstRequest,
		setFirstRequest,
		getDelayTitle,
		getStatusInfo: { title, color },
		setDelay,
		getIntervalHandler,
	} = store;
	const {
		authStore: { setLogout },
	} = useStore() as IStore;

	useEffect(() => {
		if (getFirstRequest === 0) {
			setFirstRequest(1);
		} else {
			wsConnection(getIntervalHandler, setDelay, setLogout);
		}
	}, [getFirstRequest]);

	return (
		<div data-testid="statusConnection" className={s.statusConnection}>
			<Sentry.ErrorBoundary>
				<span data-testid="title" className={s.title}>
					{title}
				</span>
				<Tooltip title={getDelayTitle()} placement="topLeft">
					<BarChartOutlined style={{ color }} />
				</Tooltip>
			</Sentry.ErrorBoundary>
		</div>
	);
};
export default observer(StatusConnection);
