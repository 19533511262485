import { Params, useMatches } from "react-router-dom";
import { Breadcrumb } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { observer } from "mobx-react-lite";

import { CrumbHandle } from "@/app/routing/types";
import { filterBoolean } from "@/shared/utils/array";

import { BreadCrumbItem } from "./bread-crumb-item";
import { useCheckBreadcrumbsAbilities } from "./hooks";

const DEFAULT_ITEMS: ItemType[] = [
	{
		path: "/",
		title: "Home",
	},
];

const renderItems = <T extends AnyObject = AnyObject>(
	route: ItemType,
	_params: T,
	_routes: ItemType[],
	paths: string[]
) => <BreadCrumbItem route={route} paths={paths} />;

interface IMatches {
	id: string;
	pathname: string;
	params: Params<string>;
	data: unknown;
	handle: unknown;
}

export const BreadCrumbs = observer(() => {
	const matches: IMatches[] = useMatches();

	const crumbs = filterBoolean(matches.map((match) => (match?.handle as CrumbHandle)?.crumb));

	const filteredCrumbs = useCheckBreadcrumbsAbilities(crumbs);

	const items = crumbs.length ? [...DEFAULT_ITEMS, ...filteredCrumbs] : DEFAULT_ITEMS;

	return <Breadcrumb itemRender={renderItems} items={items} />;
});
