import { useEffect, useState } from "react";
import dayjs from "dayjs";

import s from "./clock.module.scss";

export const Timer = () => {
	const now = dayjs().utc().format("YYYY/MM/DD HH:mm:ss");
	const [clock, setClock] = useState<string | null>(null);

	useEffect(() => {
		const tick = setTimeout(() => {
			setClock(now);
		}, 1000);
		return () => clearTimeout(tick);
	}, [clock]);

	return (
		<span data-testid="time" className={s.time}>
			{clock && `${clock} (UTC)`}
		</span>
	);
};
