import { App as AppContainer, ConfigProvider, Layout, ThemeConfig } from "antd";
import { observer } from "mobx-react-lite";

import { IStore } from "@/app/model";
import { useStore } from "@/app/model/use-store";
import { Aside } from "@/app/template/aside";
import { Footer } from "@/app/template/footer";
import { Header } from "@/app/template/header";
import s from "@/app/template/template.module.scss";
import { useConnectPartyAbilities } from "@/features/check-abilities";
import { ColorTheme } from "@/shared/types/types";
import { UIModalConsent } from "@/shared/ui/ui-modal-consent";
import { ToasterInit } from "@/shared/ui/ui-toaster";
import dark from "@/styles/dark.json";
import light from "@/styles/light.json";

import { useGetPartyFromUrl } from "./hooks";
import { Routing } from "./routing";

type ThemeTokenMap = Record<ColorTheme, ThemeConfig>;

const themes: ThemeTokenMap = { light, dark };

export const App = observer(() => {
	const { getTheme, getIsMobile, authStore } = useStore() as IStore;
	const { getIsAuth } = authStore;

	const party = useGetPartyFromUrl();

	useConnectPartyAbilities(party);

	return (
		<ConfigProvider theme={{ cssVar: true, ...themes[getTheme] }}>
			<AppContainer data-testid="template" className={s.template}>
				<Header />

				<main data-testid="main" className={s.main}>
					{getIsAuth && !getIsMobile && <Aside />}

					<Layout data-testid="content" className={s.content}>
						<Routing />
					</Layout>
				</main>

				{getIsAuth && <Footer />}

				<ToasterInit theme={getTheme} />

				<UIModalConsent />
			</AppContainer>
		</ConfigProvider>
	);
});
