const HTTPS_PROTOCOL = "https://";
const WS_PROTOCOL = "wss://";
const API_VERSION = "/api/v1";
const WS_PREFIX = "/ws/";

const baseUrl = import.meta.env.VITE_APP_API_URL;

export const apiUrl = HTTPS_PROTOCOL + baseUrl + API_VERSION;

export const wsUrl = WS_PROTOCOL + baseUrl + WS_PREFIX;
