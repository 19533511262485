import { Theme, ToastContainer, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CONTAINER_ID } from "../consts";
import { uiToast } from "../toast";

import s from "./styles.module.scss";

interface ToasterInitProps {
	theme: Theme;
}

const clickToastHandler = (e: React.MouseEvent, containerId: string) => {
	if (e.shiftKey) uiToast.dismiss({ containerId });
};

const getContainerProps = (theme: Theme, containerId: string): ToastContainerProps => ({
	theme,
	containerId,
	closeOnClick: true,
	stacked: true,
	pauseOnFocusLoss: true,
	hideProgressBar: true,
	toastClassName: s.toastContainer,
	bodyClassName: s.toastBody,
	className: s[containerId],
	onClick: (e) => clickToastHandler(e, containerId),
});

export const ToasterInit = ({ theme }: ToasterInitProps) => (
	<>
		<ToastContainer {...getContainerProps(theme, CONTAINER_ID.DEFAULT)} position="top-right" />

		<ToastContainer {...getContainerProps(theme, CONTAINER_ID.SUCCESS)} position="top-center" />
	</>
);
