import { RollbackOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { LayerType } from "@/app/routing/types";

import { useMoveLayer } from "./hooks";

interface BackLayerButtonProps {
	layer: LayerType;
}

export const BackLayerButton = ({ layer }: BackLayerButtonProps) => {
	const moveLayer = useMoveLayer(layer);

	return (
		<Button icon={<RollbackOutlined />} onClick={() => moveLayer()}>
			Back
		</Button>
	);
};
