import { observer } from "mobx-react-lite";

import { Clock } from "@/app/template/footer/clock";
import StatusConnection from "@/app/template/footer/status-connection";

import s from "./footer.module.scss";

export const Footer = observer(() => (
	<footer data-testid="footer" className={s.footer}>
		<Clock />
		<StatusConnection />
	</footer>
));
