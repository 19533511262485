import { ErrorInfo } from "react";
import * as Sentry from "@sentry/react";

import { showErrorMsg } from "@/shared/ui/ui-toaster";

import { ErrorBoundaryFallback } from "./error-boundary-fallback";

export interface ErrorBoundaryProps {
	fallback?: JSX.Element;
}

export interface ErrorBoundaryState {
	hasError: boolean;
}

export class ErrorBoundary extends Sentry.ErrorBoundary {
	static defaultProps: Sentry.ErrorBoundaryProps = {
		fallback: ErrorBoundaryFallback,
	};

	componentDidCatch(
		error: Error & {
			cause?: Error;
		},
		errorInfo: ErrorInfo
	) {
		super.componentDidCatch(error, errorInfo);
		// You can also log the error to an error reporting service
		if (error instanceof Error) showErrorMsg(error.message);
	}
}
