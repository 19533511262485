/* eslint-disable no-param-reassign */
import { extend } from "dayjs";
import utc from "dayjs/plugin/utc";
import { toJS } from "mobx";
import { Instance, types as t } from "mobx-state-tree";

import { WSPingEnum } from "@/shared/types/types";

extend(utc);

const WSStore = t
	.model("WebSocketStore", {
		intervalHandler: t.optional(t.number, 5000),
		delay: t.optional(t.number, 50),
		firstRequest: t.optional(t.number, 0),
		connectionType: WSPingEnum.PING,
		type: t.optional(
			t.enumeration<WSPingEnum>("WSPingEnum", Object.values(WSPingEnum)),
			WSPingEnum.PING
		),
	})
	.views((self) => ({
		get getFirstRequest(): number {
			return toJS(self.firstRequest);
		},
		get getIntervalHandler(): number {
			return toJS(self.intervalHandler);
		},
		get getConnectionType(): WSPingEnum {
			return toJS(self.type);
		},
		get getType(): WSPingEnum {
			return toJS(self.type);
		},
		get getDelay(): number {
			return toJS(self.delay);
		},
		get getStatusInfo() {
			let status = "";
			let color = "";
			switch (true) {
				case self.delay === 0:
					status = self.type === WSPingEnum.PING ? "No connection" : "No data";
					color = "#CDCED1";
					break;
				case self.delay > 150:
					status = "bad";
					color = "#D84E4E";
					break;
				case self.delay > 80:
					status = "normal";
					color = "#F2BD01";
					break;
				case self.delay > 50:
					status = "good";
					color = "#007DFF";
					break;
				case self.delay <= 50:
					status = "great";
					color = "#69B40A";
					break;
			}
			return toJS({
				color,
				title: `${status?.charAt(0).toUpperCase() || ""}${status?.slice(1) || ""}`,
			});
		},
	}))
	.actions((self) => {
		const setDelay = (delay: number) => {
			self.delay = delay;
		};
		const setType = (type: WSPingEnum) => {
			self.type = type;
		};
		const setConnectionType = (connectionType: WSPingEnum) => {
			self.connectionType = connectionType;
		};
		const setFirstRequest = (firstRequest: number) => {
			self.firstRequest = firstRequest;
		};
		const getDelayTitle = (): string => {
			const title = self.connectionType === WSPingEnum.PING ? "Server" : "Exchange";
			return !self.delay ? "No connection" : `${title} delay ${self.delay} ms`;
		};

		return {
			setDelay,
			setFirstRequest,
			getDelayTitle,
			setType,
			setConnectionType,
		};
	});

export type IWSStore = Instance<typeof WSStore>;
export default WSStore;
