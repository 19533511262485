import { useMatch, useNavigate } from "react-router-dom";

import { BOT_ROUTE } from "@/app/constants/routes";
import { LayerType, MainRoutes, PartyRoutes } from "@/app/routing/types";
import { getPartySectionLink } from "@/app/routing/utils";
import { noOp } from "@/shared/constants/functions";

const useGetPartyDefaultPath = () => {
	const match = useMatch(BOT_ROUTE);

	const party = match?.params.party;

	let partyURL = "";

	if (party) partyURL = getPartySectionLink({ party, partySection: PartyRoutes.Bots });

	return { partyURL };
};

export const useMoveLayer = (layer: LayerType) => {
	const navigate = useNavigate();

	const { partyURL } = useGetPartyDefaultPath();

	switch (layer) {
		case "bot": {
			return () => navigate(partyURL);
		}
		case "party": {
			return () => navigate(MainRoutes.AllBots);
		}

		default:
			return noOp;
	}
};
