import { IPathParams, IRouteConfig } from "@/app/routing/types";
import { PermissionWrapper } from "@/features/check-abilities";

import { MenuItem, MenuItemProps } from "../../menu-item";

export const useGetNavigationList = (items: IRouteConfig[], pathParams?: IPathParams) =>
	items.map(({ ability, menuItem, path }) => {
		const menuItemProps: MenuItemProps = {
			route: path,
			menuItem,
			pathParams,
		};

		return ability ? (
			<PermissionWrapper showInfoMsg={false} key={menuItem?.title} abilityName={ability}>
				<MenuItem {...menuItemProps} />
			</PermissionWrapper>
		) : (
			<MenuItem key={menuItem?.title} {...menuItemProps} />
		);
	});
