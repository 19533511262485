import { jwtDecode } from "jwt-decode";
import { toJS } from "mobx";
import { Instance, types as t } from "mobx-state-tree";

import {
	getAbilitiesFromLocalStorage,
	getRefreshToken,
	setAccessToken,
	setRefreshToken,
} from "@/shared/api/utils/get-token";
import { LocalStorageKeys } from "@/shared/constants/local-storage-keys";
import { Abilities } from "@/shared/types/abilities-types";
import { getCurrentUnix } from "@/shared/utils/date-utils";
import { setToLocalStorage } from "@/shared/utils/local-storage";

const DecodedToken = t.model("DecodedToken", {
	exp: t.number,
	iat: t.number,
	id: t.number,
	name: t.string,
	email: t.string,
	is_active: t.boolean,
	tg_handler: t.string,
	group_type: t.string,
	group_name: t.string,
	user_groups: t.optional(t.frozen(), {}),
});

export type IDecodedToken = Instance<typeof DecodedToken>;

export const AuthStore = t
	.model("AuthStore", {
		isLoading: t.boolean,
		login: t.maybeNull(t.string),
		isAuth: t.boolean,
		token: t.maybeNull(t.string),
		refreshToken: t.maybeNull(t.string),
		refreshTokenExpTime: t.maybeNull(t.number),
		isUserActive: t.boolean,
		temporaryToken: t.maybeNull(t.string),
		decodedToken: t.maybe(DecodedToken),
		tokenIsExpired: true,
	})
	.views((self) => ({
		get getIsUserActive() {
			return toJS(self.isUserActive);
		},
		get getIsAuth() {
			return !!self.token && !self.tokenIsExpired;
		},
		get getIsLoading() {
			return toJS(self.isLoading);
		},
		get getLogin() {
			return toJS(self.login);
		},
		get abilities() {
			return getAbilitiesFromLocalStorage();
		},
		get userLogin() {
			return self.decodedToken?.name || "";
		},
	}))
	.actions((self) => {
		const setIsLoading = (status: boolean) => {
			self.isLoading = status;
		};
		const setIsActiveUser = (status: boolean) => {
			self.isUserActive = status;
		};
		const setLogin = (login: string | null) => {
			self.login = login;
		};
		const setToken = (token: string) => {
			self.token = token;
		};

		const setTokens = (refreshToken: string, token: string) => {
			setToLocalStorage(LocalStorageKeys.RefreshToken, refreshToken);
			setToLocalStorage(LocalStorageKeys.AuthToken, token);
			self.refreshToken = refreshToken;

			const decodedToken = jwtDecode<IDecodedToken>(refreshToken);

			self.refreshTokenExpTime = decodedToken.exp;
		};
		const setIsAuth = (status: boolean) => {
			self.isAuth = status;
		};
		const setAbilities = (abilities: Abilities) => {
			setToLocalStorage(LocalStorageKeys.Abilities, JSON.stringify(abilities));
		};
		const setLogout = () => {
			setAccessToken("");
			setRefreshToken("");
			self.token = null;
			self.refreshToken = null;
			self.isUserActive = false;
			self.isAuth = false;
			self.login = null;
			self.isLoading = false;
			self.tokenIsExpired = true;
		};
		const setIsUserActive = (token: string) => {
			const userInfo = jwtDecode<IDecodedToken>(token);
			if (userInfo) {
				self.temporaryToken = token;
				self.isUserActive = userInfo.is_active;
				self.login = userInfo.name;
				self.isLoading = false;
			}
		};

		const setCheckIsUserActive = (token: string, refreshToken: string) => {
			const userInfo = jwtDecode<IDecodedToken>(token);
			if (userInfo) {
				self.token = token;
				self.refreshToken = refreshToken;
				self.isUserActive = userInfo.is_active;
				self.login = userInfo.name;
				self.isLoading = false;
				self.isAuth = true;
			}
		};

		const setAuthInfo = (refreshToken: string, accessToken: string, abilities: Abilities) => {
			setTokens(refreshToken, accessToken);
			setAbilities(abilities);
		};

		const receiveTokenFromLocalStorage = () => {
			const refreshToken = getRefreshToken();

			if (!refreshToken) return;

			setToken(refreshToken);

			self.decodedToken = self.token ? jwtDecode(self.token) : undefined;

			if (self.decodedToken?.exp !== undefined) {
				self.tokenIsExpired = self.decodedToken.exp <= getCurrentUnix();
			} else self.tokenIsExpired = true;
		};

		return {
			setLogin,
			setIsAuth,
			setIsUserActive,
			setIsLoading,
			setCheckIsUserActive,
			setLogout,
			setAbilities,
			setIsActiveUser,
			receiveTokenFromLocalStorage,
			setAuthInfo,
		};
	});
export default AuthStore;
export type IAuthStore = Instance<typeof AuthStore>;
