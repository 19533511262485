import { ToastOptions as ToastOptionsDefault } from "react-toastify";

import { uiToast } from "./toast";
import { ToastWrapper } from "./toast-wrapper";
import { ToastContentObj, ToastType } from "./types";

type ToastContent = string | Partial<ToastContentObj>;

interface ToastOptions extends ToastOptionsDefault {
	showCopy?: boolean;
}

const showToast = (toastType: ToastType, content?: ToastContent, options?: ToastOptions) => {
	const contentProps = typeof content === "string" ? { title: content } : content;
	const defaultCopyMsg = toastType === "error";
	const { showCopy = defaultCopyMsg, ...restOptions } = options || {};

	return uiToast[toastType](
		<ToastWrapper toastType={toastType} showCopy={showCopy} {...contentProps} />,
		restOptions
	);
};

export const showErrorMsg = (content?: ToastContent, options?: ToastOptions) =>
	showToast("error", content, options);

export const showSuccessMsg = (content?: ToastContent, options?: ToastOptions) =>
	showToast("success", content, options);
