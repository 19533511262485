import { Abilities } from "../../../shared/types/abilities-types";

export const checkParty = (abilities: Abilities, abilityName: string, party: string): boolean => {
	if (!abilities) return false;

	// condition for root
	if (abilities.root) return true;

	const checkParty = abilities[abilityName]?.find((el) => el === party);

	return Boolean(checkParty);
};

export const checkAbility = (abilities: Abilities, abilityName: string) => {
	if (abilities[abilityName]) return true;

	return false;
};
