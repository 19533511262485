// Example: ["/api/url-name-1", "/api/url-name-2"]
const ignoreUrlForAnyCodes: string[] = [];

/** if set value as [""], than will be ignored any urls for this code */
const ignoreCodesForSpecificUrls: Record<number, string[]> = {
	// Example:
	// 501: ["/api/url-name-1"],
	// 502: ["/api/url-name-1", "/api/url-name-2"],
	// 503: [""],
};

const includesUrl = (request: string, urls: string[]) => urls.some((url) => request.includes(url));

export const checkIsShowError = (status: number | null, request: string) => {
	if (status === null) return true;

	if (includesUrl(request, ignoreUrlForAnyCodes)) return false;

	const ignoreUrls = ignoreCodesForSpecificUrls[status];

	if (ignoreUrls?.length && includesUrl(request, ignoreUrls)) return false;

	return true;
};
