import { useEffect } from "react";

import { IStore } from "@/app/model";
import { useStore } from "@/app/model/use-store";

export const useConnectPartyAbilities = (party: string | null) => {
	const { abilitiesState } = useStore() as IStore;
	const { setParty, resetParty } = abilitiesState;

	useEffect(() => {
		setParty(party);

		return () => {
			resetParty();
		};
	}, [setParty, resetParty, party]);
};
