import { useParams } from "react-router-dom";

import {
	CEX_ROUTES_SCHEME,
	DEX_ROUTES_SCHEME,
	DEX_V2_ROUTES_SCHEME,
} from "@/app/routing/route-scheme";
import { BotType, IRouteConfig } from "@/app/routing/types";

import { useGetNavigationList } from "./shared/hooks/use-get-navigation-list";

interface BotQueryParams {
	party: string;
	uuid: string;
}

interface BotNavigationListProps {
	botType: BotType;
}

const MENU_ITEMS_MAP: Record<BotType, IRouteConfig[]> = {
	CEX: CEX_ROUTES_SCHEME[0].children || [],
	DEX: DEX_ROUTES_SCHEME[0].children || [],
	DEX_V2: DEX_V2_ROUTES_SCHEME[0].children || [],
};

const useGetPathParams = () => {
	const { party, uuid } = useParams<keyof BotQueryParams>() as BotQueryParams;

	return { party, uuid };
};

export const BotNavigationList = ({ botType }: BotNavigationListProps) => {
	const menuItems = MENU_ITEMS_MAP[botType];
	const pathParams = useGetPathParams();

	const list = useGetNavigationList(menuItems, pathParams);

	return list;
};
