import {
	BotLayerParams,
	BotType,
	CrumbHandle,
	GetBotLinkProps,
	GetPartyLinkProps,
	IMenuItem,
	IPathParams,
	PartyPage,
} from "./types";

export const getBotRoutePath = ({ botType }: BotLayerParams) => `${botType}/:uuid/`;

export const getBotLink = <T extends BotType>({
	party,
	botType,
	uuid,
	botTab,
}: GetBotLinkProps<T>) => `/party/${party}/${botType}/${uuid}${botTab ? `/${botTab}` : ""}`;

export const getPartySectionLink = ({ party, partySection }: GetPartyLinkProps) =>
	`/party/${party}/${partySection}`;

export interface GetPartyMenuItemProps extends Pick<IMenuItem, "title" | "icon"> {
	section: PartyPage;
}

export const getPartyMenuItem = ({ section, title, icon }: GetPartyMenuItemProps): IMenuItem => ({
	title,
	icon,
	to: ({ party }: IPathParams) => getPartySectionLink({ partySection: section, party }),
});

interface GetBotMenuItemProps<T extends BotType>
	extends Pick<IMenuItem, "title" | "icon">,
		Pick<GetBotLinkProps<T>, "botType" | "botTab"> {}

export const getBotMenuItem = <T extends BotType>({
	title,
	icon,
	botType,
	botTab,
}: GetBotMenuItemProps<T>): IMenuItem => ({
	title,
	icon,
	to: ({ party, uuid }: IPathParams) => getBotLink({ botType, botTab, party, uuid }),
});

export const getDefaultCrumbConfig = (path: string): CrumbHandle => ({
	crumb: {
		path,
		title: path,
	},
});
