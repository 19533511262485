import { createRoot } from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { httpClientIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";

import store from "@/app/model";
import StoreProvider from "@/app/model/provider";
import { DeviceProvider } from "@/shared/hooks/use-device-check";
import "@/shared/utils/yup/initializer";
import "@/styles/global.scss";

import { App } from "./App";
import { routesItems } from "./routing";

Sentry.init({
	// dsn: import.meta.env.VITE_SENTRY_DSN,
	integrations: [
		httpClientIntegration(),
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: true,
		}),
	],
	sendDefaultPii: true,
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost"],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const gotBitStore = store.create({}, {});

const router = createBrowserRouter([
	{
		path: "/*",
		element: <App />,
		children: routesItems,
	},
]);

createRoot(document.getElementById("root") as HTMLElement).render(
	// <React.StrictMode>
	<StoreProvider store={gotBitStore}>
		<DeviceProvider>
			<RouterProvider router={router} future={{ v7_startTransition: true }} />
		</DeviceProvider>
	</StoreProvider>
	// </React.StrictMode>
);
