import { BreadcrumbItemProps } from "antd";

import { IMenuItem, IRouteConfig } from "../types";

export const getCrumbsMenu = (
	routesScheme: IRouteConfig[],
	CrumbMenuItem: (props: IMenuItem) => JSX.Element | null
): BreadcrumbItemProps["menu"] => {
	const menuConfigs = routesScheme.filter(({ menuItem }) => !!menuItem);

	const items = menuConfigs.map(({ ability, menuItem }, index) => {
		const title = <CrumbMenuItem {...menuItem} />;

		return {
			key: index,
			title,
			ability,
		};
	});

	return { items };
};
