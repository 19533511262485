import { useMatch } from "react-router-dom";

import { BOT_ROUTE, PARTY_SECTION_PATH } from "@/app/constants/routes";

export const useGetPartyFromUrl = () => {
	const partyRoute = useMatch(PARTY_SECTION_PATH);

	if (!partyRoute) return null;

	const { party } = partyRoute.params;

	return party || null;
};

export const useGetPartyAndBotUUIDFromUrl = () => {
	const botRoute = useMatch(BOT_ROUTE);

	if (!botRoute) return null;

	const { party, uuid } = botRoute.params;

	if (party && uuid) return { party, uuid };

	return null;
};
