import { useCallback, useState } from "react";
import { CheckCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";

import { setTextClipboard } from "@/shared/utils/clipboard";

import s from "./styles.module.scss";

const TOAST_PROMPT_TEXT = "shift + click - close all";

interface ToastHelpersProps {
	copyMsg: string | null;
}

export const ToastHelpers = ({ copyMsg }: ToastHelpersProps) => {
	const [isCopied, setIsCopied] = useState(false);

	const title = isCopied ? "Copied" : "Copy";

	const copyText = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.stopPropagation();

			if (!copyMsg) return;

			setTextClipboard(copyMsg, {
				isShowSuccessMsg: false,
				onSuccess: () => setIsCopied(true),
			});
		},
		[copyMsg]
	);

	return (
		<Flex gap={8} align="center" className={s.helpersWrapper}>
			{TOAST_PROMPT_TEXT}

			{copyMsg && (
				<Button
					title={title}
					onClick={copyText}
					icon={isCopied ? <CheckCircleOutlined /> : <CopyOutlined />}
					type="text"
					size="small"
					className={s.copyButton}
				/>
			)}
		</Flex>
	);
};
