import dayjs from "dayjs";
import { AnyObject, Flags, Maybe, Message, NumberSchema, Schema } from "yup";

import { ERRORS_MSGS } from "../forms";

export const requiredNumber = <
	TType extends Maybe<number> = number | undefined,
	TContext = AnyObject,
	TDefault = undefined,
	TFlags extends Flags = "",
>(
	schema: NumberSchema<TType, TContext, TDefault, TFlags>,
	message?: Message
) => schema.transform((value) => (isNaN(value) ? undefined : value)).defaultRequired(message);

export const defaultRequired = <
	TType = any,
	TContext = any,
	TDefault = any,
	TFlags extends Flags = "",
>(
	schema: Schema<TType, TContext, TDefault, TFlags>,
	message: Message = ERRORS_MSGS.isRequired
) => schema.required(message);

export const minUnixDateValidation = <
	TType extends Maybe<number>,
	TContext,
	TDefault,
	TFlags extends Flags,
>(
	schema: NumberSchema<TType, TContext, TDefault, TFlags>,
	date: string
) => schema.min(dayjs.utc(date).unix(), `Date must be >= ${date}`);
