import { Suspense } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { IStore } from "@/app/model";
import { useStore } from "@/app/model/use-store";
import { PermissionWrapper } from "@/features/check-abilities";
import { Loading } from "@/shared/loading";
import { ErrorBoundary } from "@/shared/ui/ui-error-boundary";

import { AUTH_ROUTES_SCHEME, ROUTES_SCHEME } from "./route-scheme";
import { IRouteConfig } from "./types";

const getRoutes = (routes: IRouteConfig[]): RouteObject[] =>
	routes.map(({ path, element, ability, children, ...restProps }) => {
		const wrappedElement = element && (
			<ErrorBoundary>
				{ability ? <PermissionWrapper abilityName={ability}>{element}</PermissionWrapper> : element}
			</ErrorBoundary>
		);

		const childRoutes = children ? getRoutes(children) : undefined;

		return {
			path,
			element: wrappedElement,
			children: childRoutes,
			...restProps,
		};
	});

export const routesItems = getRoutes(ROUTES_SCHEME);
const authRoutesItems = getRoutes(AUTH_ROUTES_SCHEME);

export const Routing = observer(() => {
	const { authStore } = useStore() as IStore;
	const { getIsAuth } = authStore;

	const Routers = () => useRoutes(routesItems);
	const AuthRoutes = () => useRoutes(authRoutesItems);

	return <Suspense fallback={<Loading />}>{getIsAuth ? <Routers /> : <AuthRoutes />}</Suspense>;
});
