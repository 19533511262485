import { showErrorMsg, showSuccessMsg } from "@/shared/ui/ui-toaster";

interface TextClipboardOptions {
	isShowSuccessMsg?: boolean;
	onSuccess?: () => void;
}

export const setTextClipboard = async (text: string, options?: TextClipboardOptions) => {
	const { isShowSuccessMsg = true, onSuccess } = options || {};

	if (navigator.clipboard) {
		try {
			await navigator.clipboard.writeText(text);

			if (onSuccess) onSuccess();

			if (isShowSuccessMsg) showSuccessMsg("Copied!");
		} catch {
			showErrorMsg("Something went wrong");
		}
	} else fallback(text);
};

const fallback = (text: string) => {
	const isIos = navigator.userAgent.match(/ipad|iphone/i);
	const textarea = document.createElement("textarea");

	// create textarea
	textarea.value = text;

	// ios will zoom in on the input if the font-size is < 16px
	textarea.style.fontSize = "20px";
	document.body.appendChild(textarea);

	// select text
	if (isIos) {
		const range = document.createRange();
		range.selectNodeContents(textarea);

		const selection = window.getSelection();
		if (selection) {
			selection.removeAllRanges();
			selection.addRange(range);
		}
		textarea.setSelectionRange(0, 999999);
	} else {
		textarea.select();
	}

	// copy selection
	document.execCommand("copy"); // TODO: change it! Deprecated

	// cleanup
	document.body.removeChild(textarea);

	showSuccessMsg("Copied!");
};
