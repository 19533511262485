export enum LocalStorageKeys {
	Abilities = "abilities",
	Theme = "theme",
	AuthToken = "token",
	RefreshToken = "refreshToken",
	CurrentBotTab = "currentBot",
	WatchList = "watchListEnabled",
	SearchParams = "searchParams",
	GoogleAccessToken = "google_access_token",
}
