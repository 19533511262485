import { ModalFuncProps } from "antd";
import { makeAutoObservable } from "mobx";

import { noOp } from "@/shared/constants/functions";

import { ConfirmModalType } from "../types";

interface ShowModalProps {
	modalProps: ModalFuncProps;
	func: () => void;
	type?: ConfirmModalType;
}

const DEFAULT_MODAL_PROPS: ModalFuncProps = {
	cancelText: "No",
	okText: "Yes",
	zIndex: 9999,
};

class ConfirmModalStore {
	private _modalType: ConfirmModalType = "warning";

	private _isOpen: boolean = false;

	private _funcSend: () => Promise<void> | void = noOp;

	private _modalProps: ModalFuncProps = DEFAULT_MODAL_PROPS;

	constructor() {
		makeAutoObservable(this);
	}

	get isOpen() {
		return this._isOpen;
	}

	get modalType() {
		return this._modalType;
	}

	get modalProps(): ModalFuncProps {
		return {
			...this._modalProps,
			onOk: this.sendData,
			onCancel: this.handleCancel,
		};
	}

	handleCancel = () => {
		this._hideModal();

		this._resetState();
	};

	showModal = ({ func, modalProps, type }: ShowModalProps) => {
		this._modalProps = { ...this._modalProps, ...modalProps };

		this._funcSend = func;

		if (type) this._modalType = type;

		this._showModal();
	};

	sendData = async () => {
		await this._funcSend();

		this._hideModal();

		this._resetState();
	};

	private _hideModal = () => {
		this._isOpen = false;
	};

	private _showModal = () => {
		this._isOpen = true;
	};

	private _resetState = () => {
		this._modalProps = DEFAULT_MODAL_PROPS;
		this._funcSend = () => {};
	};
}

export const confirmModalState = new ConfirmModalStore();
