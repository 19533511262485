import { toJS } from "mobx";
import { Instance, SnapshotIn, SnapshotOut, t } from "mobx-state-tree";

import { AbilitiesStore } from "@/features/check-abilities/model";
import { AuthStore } from "@/pages/login/model";
import { LocalStorageKeys } from "@/shared/constants/local-storage-keys.ts";
import { MOBILE_DEVICE_WIDTH } from "@/shared/constants/media-query";
import { ColorTheme } from "@/shared/types/types";
import { checkDevice } from "@/shared/utils/check-device";
import { deleteFromLocalStorage } from "@/shared/utils/local-storage";
import { getFromLocalStorage } from "@/shared/utils/local-storage/get-from-local-storage";
import { setToLocalStorage } from "@/shared/utils/local-storage/set-to-local-storage";

const Store = t
	.model("Store", {
		isAppLoaded: false,
		isLightTheme: true,
		theme: ColorTheme.LIGHT,
		isMobile: false,
		isMobileDeviceWidth: `${MOBILE_DEVICE_WIDTH}`,
		scrollHeight: 0,
		authStore: t.optional(AuthStore, {
			isLoading: false,
			login: null,
			isAuth: false,
			token: null,
			refreshToken: null,
			isUserActive: false,
			tokenIsExpired: true,
		}),
		abilitiesState: t.optional(AbilitiesStore, {
			party: "",
		}),
		googleAccessToken: t.optional(t.maybeNull(t.string), null),
	})
	.views((self) => ({
		get getIsAppLoaded() {
			return toJS(self.isAppLoaded);
		},
		get getIsMobile() {
			return toJS(self.isMobile);
		},
		get getScrollHeight() {
			return toJS(self.scrollHeight);
		},
		get getIsMobileDeviceWidth() {
			return toJS(self.isMobileDeviceWidth);
		},
		get getIsLightTheme() {
			return toJS(self.isLightTheme);
		},
		get getTheme() {
			return self.theme as ColorTheme;
		},
		get getGoogleAccessToken() {
			return self.googleAccessToken;
		},
		get getIsLoggedIn() {
			return !!self.googleAccessToken;
		},
	}))
	.actions((self) => {
		const checkTheme = () => {
			const theme = getFromLocalStorage(LocalStorageKeys.Theme);
			if (theme) {
				self.isLightTheme = theme === ColorTheme.LIGHT;
				self.theme = theme === ColorTheme.LIGHT ? ColorTheme.LIGHT : ColorTheme.DARK;
			} else {
				setToLocalStorage(LocalStorageKeys.Theme, ColorTheme.LIGHT);
			}
		};
		const checkToken = () => {
			self.authStore.receiveTokenFromLocalStorage();
		};
		const setAppLoaded = (status: boolean) => {
			self.isAppLoaded = status;
		};
		const setIsLightTheme = (status: boolean) => {
			setToLocalStorage(LocalStorageKeys.Theme, status ? ColorTheme.LIGHT : ColorTheme.DARK);
			self.isLightTheme = status;
			self.theme = status ? ColorTheme.LIGHT : ColorTheme.DARK;
		};

		const setIsMobile = (status?: boolean) => {
			self.isMobile = status || checkDevice(MOBILE_DEVICE_WIDTH);
		};
		const setScrollHeight = () => {
			self.scrollHeight =
				Math.max(
					document.body.scrollHeight,
					document.documentElement.scrollHeight,
					document.body.offsetHeight,
					document.documentElement.offsetHeight,
					document.body.clientHeight,
					document.documentElement.clientHeight
				) + 150;
		};

		const setGoogleAccessToken = (token: string | null) => {
			if (token) {
				setToLocalStorage(LocalStorageKeys.GoogleAccessToken, token);
			} else {
				deleteFromLocalStorage(LocalStorageKeys.GoogleAccessToken);
			}
			self.googleAccessToken = token;
		};

		const checkGoogleAccessToken = () => {
			const token = getFromLocalStorage(LocalStorageKeys.GoogleAccessToken);
			if (token) {
				self.googleAccessToken = token;
			}
		};

		const clearGoogleAccessToken = () => {
			self.googleAccessToken = "";
		};

		const afterCreate = () => {
			checkToken();
			checkTheme();
			setIsMobile();
			checkGoogleAccessToken();
			setScrollHeight();
			setAppLoaded(true);
		};
		return {
			setAppLoaded,
			afterCreate,
			setIsLightTheme,
			setIsMobile,
			clearGoogleAccessToken,
			setGoogleAccessToken,
		};
	});

export type IStore = Instance<typeof Store>;
export type IStoreSnapshotIn = SnapshotIn<typeof Store>;
export type IStoreSnapshotOut = SnapshotOut<typeof Store>;

export default Store;
