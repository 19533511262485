import { useState } from "react";
import { Flex } from "antd";
import { EllipsisConfig } from "antd/es/typography/Base";
import classNames from "classnames";

import { ToastContentObj, ToastType, CollapsiblePlace } from "../types";

import { CollapsibleButton } from "./collapsible-button";
import { InfoBlocks } from "./info-blocks";
import { ToastHelpers } from "./toast-helpers";
import { ToastTitle } from "./toast-title";
import s from "./styles.module.scss";

type ShowCollapsibleType = CollapsiblePlace | false;

interface ToastWrapperProps extends Partial<ToastContentObj> {
	showCopy: boolean;
	toastType: ToastType;
}

const getCopyMsg = ({ title, infoBlocks = [] }: Partial<ToastContentObj>) => {
	const titleBlock = title ? `${title}\n\n` : "";

	const info = infoBlocks
		.map(({ title, message }) => {
			const preparedTitle = title ? `${title}:\n` : "";
			return `${preparedTitle}${message}\n\n`;
		})
		.join("");

	return `${titleBlock}${info}`.trim();
};

const getExpandable = (
	place: CollapsiblePlace,
	showCollapsible: ShowCollapsibleType,
	isLastInfoBlock?: boolean
): EllipsisConfig["expandable"] => {
	if (showCollapsible !== place) return false;

	if (showCollapsible === "infoBlock" && !isLastInfoBlock) return false;

	return "collapsible";
};

const getRows = (showCollapsible: ShowCollapsibleType, place: CollapsiblePlace) =>
	showCollapsible === place && place === "title" ? 4 : 1;

const getCollapsibleButton = (expanded: boolean) => <CollapsibleButton isExpanded={expanded} />;

export const ToastWrapper = ({ title, infoBlocks, showCopy, toastType }: ToastWrapperProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [showCollapsible, setShowCollapsible] = useState<ShowCollapsibleType>(false);

	const preparedCopyMsg = showCopy ? getCopyMsg({ title, infoBlocks }) : null;

	const onExpand: EllipsisConfig["onExpand"] = (e, { expanded }) => {
		e.stopPropagation();
		setIsExpanded(expanded);
	};

	const getEllipsisProps = (
		place: CollapsiblePlace,
		isLastInfoBlock?: boolean
	): EllipsisConfig => ({
		expanded: isExpanded,
		expandable: getExpandable(place, showCollapsible, isLastInfoBlock),
		rows: getRows(showCollapsible, place),
		symbol: getCollapsibleButton,
		onExpand,
		/** Calling with `true` when text have ellipsis */
		onEllipsis: (ellipsis) => ellipsis && setShowCollapsible(place),
	});

	return (
		<Flex
			vertical
			gap={4}
			justify="center"
			className={classNames(s.toastWrapper, s[`${toastType}ToastWrapper`])}
			onMouseLeave={() => setIsExpanded(false)}
		>
			<ToastHelpers copyMsg={preparedCopyMsg} />

			<ToastTitle toastType={toastType} title={title} {...getEllipsisProps("title")} />

			{infoBlocks && <InfoBlocks infoBlocks={infoBlocks} getEllipsisProps={getEllipsisProps} />}
		</Flex>
	);
};
