import { useMatch } from "react-router-dom";

import { BOT_ROUTE } from "@/app/constants/routes";

const useGetBotSectionTitle = () => {
	const botRoute = useMatch(BOT_ROUTE);

	if (!botRoute) return "";

	const { botType, uuid } = botRoute.params;

	return `${botType} • ${uuid}`;
};

export const BotCrumbTitle = () => {
	const title = useGetBotSectionTitle();
	return <span>{title}</span>;
};
