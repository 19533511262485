import { refreshToken } from "@/shared/api/endpoints";
import { wsUrl } from "@/shared/constants/environments";
import { LocalStorageKeys } from "@/shared/constants/local-storage-keys";
import { WSPingEnum } from "@/shared/types/types";
import { getCurrentDayjs } from "@/shared/utils/date-utils";
import { getFromLocalStorage } from "@/shared/utils/local-storage/get-from-local-storage";

let startTime = getCurrentDayjs();

export function getSocketInfo(): { token: string; tokenInfo: string } {
	const token: string = getFromLocalStorage(LocalStorageKeys.AuthToken) as string;
	const tokenInfo: string = JSON.stringify({
		token,
		type: WSPingEnum.PING,
		payload: {},
	});

	startTime = getCurrentDayjs();

	return { token, tokenInfo };
}

export const wsConnection = (
	getIntervalHandler: number,
	setDelay: (value: number) => void,
	setLogout: () => void
) => {
	const socket = new WebSocket(`${wsUrl}`);
	socket.onopen = () => {
		if (socket.readyState === WebSocket.OPEN && getSocketInfo().token) {
			socket.send(getSocketInfo().tokenInfo);
		}
	};

	let restartId: ReturnType<typeof setTimeout>;

	socket.onmessage = (event) => {
		const data = JSON.parse(event.data);

		if (event && data.error && data.error === "token expired") {
			setLogout();
		} else if (event && data.result?.time && data.result?.tokenExpiredSoon) {
			refreshToken();
		} else if (event && data.result?.time) {
			restartId = setTimeout(restartSocket, getIntervalHandler);

			setDelay(getCurrentDayjs().diff(startTime, "millisecond"));
		}
	};

	function restartSocket() {
		socket.send(getSocketInfo().tokenInfo);
		clearTimeout(restartId);
	}
	socket.onclose = () => {
		wsConnection(getIntervalHandler, setDelay, setLogout);
	};

	return socket;
};
