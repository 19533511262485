import { NavLink } from "react-router-dom";
import classNames from "classnames";

import { useGetPartyFromUrl } from "../../hooks";
import { IMenuItem } from "../types";

import s from "./crumb.module.scss";

interface CrumbMenuItemProps extends IMenuItem {}

export const PartyCrumbMenuItem = ({ title, to }: CrumbMenuItemProps) => {
	const party = useGetPartyFromUrl();

	if (!party || !to) return null;

	const path = to({ party });

	return (
		<NavLink className={({ isActive }) => classNames({ [s.activeLink]: isActive })} to={path}>
			{title}
		</NavLink>
	);
};
