import { FallbackRender } from "@sentry/react";
import { Alert } from "antd";

import s from "./index.module.scss";

type SentryFallbackProps = Parameters<FallbackRender>[0];

interface ErrorBoundaryFallbackProps extends SentryFallbackProps {}

export const ErrorBoundaryFallback = ({ error, componentStack }: ErrorBoundaryFallbackProps) => {
	const errorMessage = error.toString();
	const errorDescription = componentStack;
	return (
		<Alert
			type="error"
			message={errorMessage}
			className={s.errorFallback}
			description={<pre className={s.errorFallbackDescription}>{errorDescription}</pre>}
		/>
	);
};
