import { useMemo } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ConfigProvider, Tooltip } from "antd";
import dayjs from "dayjs";

import { Timer } from "./timer";
import s from "./clock.module.scss";

const UTCTooltip = () => (
	<ConfigProvider
		theme={{
			token: {
				colorTextLightSolid: "#156CE3",
				fontSize: 10,
				colorBgSpotlight: "rgb(21 108 227 / 30%)",
			},
		}}
	>
		<Tooltip placement="bottom" title="All times displayed and stored on this platform are in UTC!">
			<InfoCircleOutlined style={{ color: "#156CE3" }} />
		</Tooltip>
	</ConfigProvider>
);

export const Clock = () => {
	const utc = useMemo(() => `UTC${dayjs().format("Z").slice(0, 3).replace(/0/g, "")}`, []);

	return (
		<div data-testid="clock" className={s.clock}>
			<p data-testid="value" className={s.value}>
				<Timer />
				<UTCTooltip />
				<span data-testid="utc" className={s.utc}>
					{utc}
				</span>
			</p>
		</div>
	);
};
