import { LocalStorageKeys } from "@/shared/constants/local-storage-keys";
import { Abilities } from "@/shared/types/abilities-types";
import { getFromLocalStorage, setToLocalStorage } from "@/shared/utils/local-storage";

export const getToken = (): string | null => {
	const token = getFromLocalStorage(LocalStorageKeys.AuthToken);

	if (token !== "undefined" && token !== null && token !== "") return token;
	return null;
};

export const getRefreshToken = (): string | null => {
	const token = getFromLocalStorage(LocalStorageKeys.RefreshToken);

	if (token !== "undefined" && token !== null && token !== "") return token;
	return null;
};

export const getAbilitiesFromLocalStorage = (): Abilities => {
	const abilities = getFromLocalStorage(LocalStorageKeys.Abilities);

	if (abilities !== "undefined" && abilities !== null && abilities !== "")
		return JSON.parse(abilities);
	return {};
};

export const setAccessToken = (token: string) => {
	setToLocalStorage(LocalStorageKeys.AuthToken, token);
};

export const setRefreshToken = (token: string) => {
	setToLocalStorage(LocalStorageKeys.RefreshToken, token);
};

export const setAbilities = (abilities: Abilities) => {
	setToLocalStorage(LocalStorageKeys.Abilities, JSON.stringify(abilities));
};
