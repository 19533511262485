import { ReactNode } from "react";
import { MenuProps } from "antd";
import duration from "dayjs/plugin/duration";

export type DayjsDuration = duration.Duration;

export type MenuItem = Required<MenuProps>["items"][number];

export enum ColorTheme {
	DARK = "dark",
	LIGHT = "light",
}

export type CommonKeys<A, B> = keyof A & keyof B;

export enum MainRoutes {
	Index = "",
	Error = "error-page",
	AllBots = "all-bots",
	MarketCheckPage = "market-check",
	AccessPage = "access",
	TeamsPage = "teams",
	CEXCreatingPage = "cex-bot-creating",
	DEXCreatingPage = "dex-bot-creating",
	AccountingPage = "accounting",
	Login = "login",
}

export enum StoryRoutes {
	PNLV2Story = "pnlv2",
	ToastsStory = "toasts",
	GraphStory = "graph",
	DatePickerStory = "date-picker",
	TableStory = "table",
	Form = "form",
	Modal = "modal",
	WidgetWrapper = "widget-wrapper",
}

export enum PartyRoutes {
	Bots = "bots",
	Dashboard = "dashboard",
	DashboardV2 = "dashboard-v2",
	Strategies = "strategies",
	Modules = "modules",
	Multigrid = "multigrid",
	Funding = "funding",
	Analytics = "analytics",
	MarketsDetailed = "markets-detailed",
}

export enum RouteEnum {
	INDEX = "",
	PARTY = "party",
	BOT = "bot",
	STORIES = "stories",
}
export type RoteType = RouteEnum.PARTY | RouteEnum.BOT | RouteEnum.STORIES;

export type RoutesHashMap = [string, boolean, ReactNode];
export const enableStories = import.meta.env.VITE_APP_ENABLE_STORIES;

export enum WSPingEnum {
	PING = "ping",
	EXCHANGE = "exchange",
}
export type WSPingType = WSPingEnum.PING | WSPingEnum.EXCHANGE;

export type SocketMessageEvent = {
	error: string | null;
	type: "pong";
	result: { id: string; time: number; tokenExpiredSoon?: boolean };
};

export enum BotsEnum {
	CEX = "CEX",
	DEX = "DEX",
	DEXV2 = "DEXV2",
	DEXNE = "DEXNE",
}

export type BotsType = BotsEnum.CEX | BotsEnum.DEX | BotsEnum.DEXV2 | BotsEnum.DEXNE;

export enum BotsStatusesEnum {
	WARNING = "warning",
	ALERT = "alert",
	CLOSED = "closed",
	ALL = "all",
	NEW = "new",
	OLD = "old",
}

export type BotsStatusesType =
	| BotsStatusesEnum.WARNING
	| BotsStatusesEnum.ALERT
	| BotsStatusesEnum.CLOSED
	| BotsStatusesEnum.ALL
	| BotsStatusesEnum.NEW
	| BotsStatusesEnum.OLD;

export type LimitVolumeCounterType = "limit" | "volume" | "counter";
